import React, { FC } from "react"
import { useGlobal } from "reactn"
import { graphql } from "gatsby"
import marked from "marked"
import Meta, { MetaProps } from "../components/Meta"
import PageHeader from "../components/PageHeader"
import Img, { ImgProps } from "../components/Img"
import autoscroll from "../utils/autoscroll"

type CommercialProject = Readonly<{
  title: string,
  text: string,
  img: ImgProps
}>

type NearingProsjekterPageTemplateProps = Readonly<{
  intro: {
    title: string,
    text: string,
    projectsButton: string,
    contactButton: string,
    area: {
      label: string,
      count: string
    },
    img: ImgProps
  },
  commercialProjects: CommercialProject[]
}>

const NearingProsjekterPageTemplate: FC<NearingProsjekterPageTemplateProps> = ({
  intro,
  commercialProjects
}) => {
  const { 1: setShowContactDialog } = useGlobal<any>("showContactDialog")
  return (
    <main
      id="nearing-prosjekter-page"
      className="bg-map text-white text-center border-b border-solid border-gray-900 pb-32 overflow-x-hidden"
    >
      <section
        id="intro"
        className="bg-cover bg-center-bottom"
        style={{ backgroundImage: `url("${intro.img.src}")` }}
      >
        <div className="min-h-screen flex items-center justify-center flex-col px-4 pt-40 pb-48 bg-black bg-opacity-50 md:pt-24 md:pb-32 xs:pt-12 xs:pb-16">
          <h1
            className="text-7xl font-normal lg:text-5xl sm:text-4xl xs:text-3xl"
            dangerouslySetInnerHTML={{
              __html: marked(intro.title)
            }}
          />

          <div className="mt-8">
            <h2
              className="mb-2 text-sm uppercase text-gray-400"
              dangerouslySetInnerHTML={{
                __html: marked(intro.area.label)
              }}
            />

            <div
              className="text-5xl font-bold tracking-wider sm:text-4xl xs:text-3xl"
              dangerouslySetInnerHTML={{
                __html: marked(intro.area.count)
              }}
            />
          </div>

          <hr className="sep bg-white w-6 my-6 xl:my-6 md:mx-auto" />

          <div
            className="mx-auto max-w-3xl w-full text-lg"
            dangerouslySetInnerHTML={{
              __html: marked(intro.text)
            }}
          />

          <div className="mt-8 mx-auto sm:flex sm:flex-col sm:items-center">
            <button
              className="btn-ghost light w-48 text-center mr-2 sm:mr-0 sm:my-3 xs:my-2"
              onClick={() => {
                autoscroll()
              }}
            >
              {intro.projectsButton}
            </button>

            <button
              className="btn-ghost w-48 text-center ml-2 sm:ml-0 border-accent text-white bg-accent sm:my-3 xs:my-2 hover:bg-white hover:border-white hover:text-black"
              onClick={() => {
                setShowContactDialog(true)
              }}
            >
              {intro.contactButton}
            </button>
          </div>
        </div>
      </section>

      {commercialProjects.map(({
        title,
        text,
        img: { src, alt }
      }, index) => (
        <section
          id={`${index}`}
          key={index}
          className="my-32 last:mb-0 sm:my-16"
        >
          <div className={`max-w-8xl mx-auto w-full px-4 flex items-center ${index % 2 === 0 ? "flex-row" : "flex-row-reverse"} xl:flex-col`}>
            <div className="flex-3 lg:flex-1 md:w-full">
              <Img
                src={src}
                alt={alt}
              />
            </div>

            <div style={{ zIndex: 1 }} className={`bg-map p-3 flex items-center ${index % 2 === 0 ? "-ml-24" : "-mr-24"} xl:self-stretch xl:mx-16 xl:-mt-16 md:mx-6 sm:-mx-3 sm:mt-0`}>
              <div className="border border-solid border-white relative w-96 py-24 xl:w-full xl:px-16 md:px-8 md:py-16 sm:px-4">
                <h2
                  className="max-w-xs mx-auto font-normal text-5xl xl:text-4xl xl:max-w-2xl"
                  dangerouslySetInnerHTML={{
                    __html: marked(title)
                  }}
                />

                <hr className="sep bg-white w-6 my-12 xl:my-6 md:mx-auto" />

                <div
                  className="mx-auto max-w-xs xl:max-w-xl"
                  dangerouslySetInnerHTML={{
                    __html: marked(text)
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      ))}
    </main>
  )
}

type NearingProsjekterPageProps = Readonly<{
  data: {
    markdownRemark: {
      frontmatter: NearingProsjekterPageTemplateProps & {
        meta: MetaProps
      }
    }
  }
}>

const NearingProsjekterPage: FC<NearingProsjekterPageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <>
      <Meta {...frontmatter.meta} />

      <PageHeader />

      <NearingProsjekterPageTemplate
        intro={frontmatter.intro}
        commercialProjects={frontmatter.commercialProjects}
      />
    </>
  )
}

export default NearingProsjekterPage

export const NearingProsjekterPageQuery = graphql`
  query NearingProsjekterPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {

        meta {
          title
          description
          css
          js
        }

        intro {
          title
          text
          projectsButton
          contactButton
          area {
            label
            count
          }
          img {
            src
            alt
          }
        }

        commercialProjects {
          title
          text
          img {
            src
            alt
          }
        }

      }
    }
  }
`
